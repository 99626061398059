export default {
  namespaced: true,
  state: {
    fieldsOfTable: [],
    fieldsOfDetail: [],
    results: [],
    isCriteriaCollapsed: true,
    isResultsShown: false,
    selectedResult: null,
  },
  mutations: {
    SET_ADVANCED_SEARCH_FIELDS_AND_RESULTS(state, theFieldsAndResults) {
      state.fieldsOfTable = theFieldsAndResults.fields.table;
      state.fieldsOfDetail = theFieldsAndResults.fields.detail;
      state.results = theFieldsAndResults.results;
      state.selectedResult = null;
    },
    SET_IS_CRITERIA_COLLAPSED(state, value) {
      state.isCriteriaCollapsed = value;
    },
    SET_IS_RESULTS_SHOWN(state, value) {
      state.isResultsShown = value;
    },
    SET_SELECTED_RESULT(state, result) {
      state.selectedResult = result;
    }
  },
  actions: {
    updateAdvancedSearchFieldsAndResults({ commit }, theFieldsAndResults) {
      commit('SET_ADVANCED_SEARCH_FIELDS_AND_RESULTS', theFieldsAndResults);
    },
    clearAdvancedSearchFieldsAndResults({ commit }) {
      const theEmpty = {
        fields: [],
        results: []
      };
      commit('SET_ADVANCED_SEARCH_FIELDS_AND_RESULTS', theEmpty);
    },
    updateIsCriteriaCollapsed({ commit }, value) {
      commit('SET_IS_CRITERIA_COLLAPSED', value);
    },
    showAdvancedSearchResults({ commit }) {
      commit('SET_IS_RESULTS_SHOWN', true);
    },
    hideAdvancedSearchResults({ commit }) {
      commit('SET_IS_RESULTS_SHOWN', false);
    },
    updateAdvancedSearchSelectedResult({ commit }, result) {
      commit('SET_SELECTED_RESULT', result);
    }
  }
};
