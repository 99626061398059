export default {
  namespaced: true,
  state: {
    sources: {
      "mapbox-dem": {
        maxzoom: 13,
        minzoom: 4,
        tileSize: 256,
        //tiles: ["https://optgeo.github.io/10b256/zxy/{z}/{x}/{y}.webp"],
        url: "mapbox://mapbox.mapbox-terrain-dem-v1",
        type: "raster-dem"
      },
      // 地理院タイル 標準地図
      "gsi-std": {
        type: "raster",
        maxzoom: 18,
        minzoom: 4,
        tiles: ["https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"],
        tileSize: 256,
        attribution:
          "<a target=_blank rel=noopener noreferrer href='https://maps.gsi.go.jp/development/ichiran.html'>国土地理院</a>"
      },
      // 地理院タイル 淡色地図
      "gsi-pale": {
        type: "raster",
        maxzoom: 18,
        minzoom: 4,
        tiles: ["https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png"],
        tileSize: 256,
        attribution:
          "<a target=_blank rel=noopener noreferrer href='https://maps.gsi.go.jp/development/ichiran.html'>国土地理院</a>"
      },
      // 地理院タイル 白地図
      "gsi-blank": {
        type: "raster",
        maxzoom: 18,
        minzoom: 4,
        tiles: ["https://cyberjapandata.gsi.go.jp/xyz/blank/{z}/{x}/{y}.png"],
        tileSize: 256,
        attribution:
          "<a target=_blank rel=noopener noreferrer href='https://maps.gsi.go.jp/development/ichiran.html'>国土地理院</a>"
      },
      // 地理院タイル 陰影起伏図
      "gsi-hillshademap": {
        type: "raster",
        maxzoom: 18,
        minzoom: 4,
        tiles: [
          "https://cyberjapandata.gsi.go.jp/xyz/hillshademap/{z}/{x}/{y}.png"
        ],
        tileSize: 256,
        attribution:
          "<a target=_blank rel=noopener noreferrer href='https://maps.gsi.go.jp/development/ichiran.html'>国土地理院</a>"
      },
      // 地理院タイル 傾斜量図
      "gsi-slopemap": {
        type: "raster",
        maxzoom: 18,
        minzoom: 4,
        tiles: [
          "https://cyberjapandata.gsi.go.jp/xyz/slopemap/{z}/{x}/{y}.png"
        ],
        tileSize: 256,
        attribution:
          "<a target=_blank rel=noopener noreferrer href='https://maps.gsi.go.jp/development/ichiran.html'>国土地理院</a>"
      },
      // 地理院タイル 色別標高図
      "gsi-relief": {
        type: "raster",
        maxzoom: 18,
        minzoom: 4,
        tiles: ["https://cyberjapandata.gsi.go.jp/xyz/relief/{z}/{x}/{y}.png"],
        tileSize: 256,
        attribution:
          "※色別標高図：海域部は海上保安庁海洋情報部の資料を使用して作成"
      },
      // 地理院タイル 空中写真
      "gsi-ort": {
        type: "raster",
        maxzoom: 18,
        minzoom: 4,
        tiles: [
          "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg"
        ],
        tileSize: 256,
        attribution:
          "<a target=_blank rel=noopener noreferrer href='https://maps.gsi.go.jp/development/ichiran.html'>国土地理院</a>"
      },
      // 静岡県 微地形表現図
      "cs-3d-map": {
        type: "raster",
        maxzoom: 18,
        minzoom: 4,
        tiles: [
          process.env.VUE_APP_SHIZUOKA_RASTER_ENDPOINT + "/CS_3D_MAP/{z}/{x}/{y}.png"
        ],
        tileSize: 256,
        attribution:
        "<a target=_blank rel=noopener noreferrer href='https://opendata.pref.shizuoka.jp/dataset/fuji-92.html'>静岡県 森林計画課</a>"
      }
    },
    layers: [
      {
        // 地理院タイル 標準地図
        id: "gsi-std",
        maxzoom: 20,
        minzoom: 3,
        type: "raster",
        source: "gsi-std",
        layout: {
          visibility: "visible"
        },
        description: {
          caption: "地理院タイル 標準地図",
          "display-order-in-category": 10
        }
      },
      {
        // 地理院タイル 淡色地図
        id: "gsi-pale",
        maxzoom: 20,
        minzoom: 3,
        type: "raster",
        source: "gsi-pale",
        layout: {
          visibility: "none"
        },
        description: {
          caption: "地理院タイル 淡色地図",
          "display-order-in-category": 20
        }
      },
      {
        // 地理院タイル 白地図
        id: "gsi-blank",
        maxzoom: 20,
        minzoom: 3,
        type: "raster",
        source: "gsi-blank",
        layout: {
          visibility: "none"
        },
        description: {
          caption: "地理院タイル 白地図",
          "display-order-in-category": 30
        }
      },
      {
        // 地理院タイル 空中写真
        id: "gsi-ort",
        type: "raster",
        maxzoom: 20,
        minzoom: 3,
        source: "gsi-ort",
        layout: {
          visibility: "none"
        },
        description: {
          caption: "地理院タイル 空中写真",
          "display-order-in-category": 40
        }
      },
      {
        // 静岡県 微地形表現図
        id: "cs-3d-map",
        type: "raster",
        maxzoom: 20,
        minzoom: 3,
        source: "cs-3d-map",
        layout: {
          visibility: "none"
        },
        description: {
          caption: "静岡県 微地形表現図",
          "display-order-in-category": 50
        }
      }
    ]
  }
};
