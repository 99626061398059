import ConstModule from "../constModule";

// 山、河川、湖沼などの名称のフィルターを作成
let filter = ["in", ConstModule.state.config.gsi_vector_tile.labelName["judgementAttribute"]];
for (const elem of ConstModule.state.config.gsi_vector_tile.labelName["displayNumber"]) {
  filter.push(elem);
}

// 海、川、湖などのレイヤー一覧
var layers = [
  {
    id: "river",
    type: ConstModule.state.config.gsi_vector_tile.riverArea["type"],
    source: "gsiVectorTileSource",
    "source-layer": ConstModule.state.config.gsi_vector_tile.riverArea["sourceLayer"],
    maxzoom: 21,
    minzoom: 3,
    paint: {
      "line-color": "#A2A1FB",
      "line-width": 1
    },
    layout: {
      visibility: "none"
    },
    metadata: {
      showInMenu: false
    }
  },
  {
    id: "waterarea",
    type: ConstModule.state.config.gsi_vector_tile.waterArea["type"],
    source: "gsiVectorTileSource",
    "source-layer": ConstModule.state.config.gsi_vector_tile.waterArea["sourceLayer"],
    maxzoom: 21,
    minzoom: 3,
    paint: {
      "fill-color": "rgb(190,210,255)"
    },
    layout: {
      visibility: "none"
    },
    metadata: {
      showInMenu: false
    }
  },
  //山、河川、湖沼などの名称
  {
    id: "label",
    type: ConstModule.state.config.gsi_vector_tile.labelName["type"],
    source: "gsiVectorTileSource",
    filter: filter,
    "source-layer": ConstModule.state.config.gsi_vector_tile.labelName["sourceLayer"],
    maxzoom: 21,
    minzoom: 4,
    paint: {
      "text-color": [
        "match",
        ["get", ConstModule.state.config.gsi_vector_tile.labelName["judgementAttribute"]],
        ConstModule.state.config.gsi_vector_tile.labelName["displayColor"]["blue"],
        ["rgb", 45, 91, 246],
        ConstModule.state.config.gsi_vector_tile.labelName["displayColor"]["yellow"],
        ["rgb", 146, 114, 39],
        ConstModule.state.config.gsi_vector_tile.labelName["displayColor"]["black"],
        ["rgb", 0, 0, 0],
        ["rgb", 105, 105, 105]
      ],
      "text-halo-color": ["rgb", 255, 255, 255],
      "text-halo-width": 2
    },
    layout: {
      visibility: "visible",
      "text-field": [
        "get",
        ConstModule.state.config.gsi_vector_tile.labelName["displayAttribute"]
      ],
      "text-size": ["interpolate", ["linear"], ["zoom"], 11, 10, 14, 20]
    },
    metadata: {
      showInMenu: false
    }
  }
];

var waterArea = {
  layers: layers,
  source: {
    type: "vector",
    tiles: [ConstModule.state.config.gsi_vector_tile["sourceUrl"]],
    minzoom: 4,
    maxzoom: 21,
    attribution:
      "<a target=_blank rel=noopener noreferrer href='https://maps.gsi.go.jp/development/ichiran.html'>国土地理院</a>"
  }
};

export default waterArea;
