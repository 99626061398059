const packageJson = require('/package.json');

const GROUP_MIN_ZOOM = 7.5;
const GROUP_MAX_ZOOM = 20;
const GROUP_BASE_OPACITY = 0.5;

// 林地開発 - 許可 レイヤー一覧
const layers = [];

layers.push(
  // 林地開発 - 許可 ポリゴン
  {
    id: "shizuoka-林地開発-許可",
    type: "fill",
    source: "shizuokaRinchiKaihatsuKyokaSource",
    "source-layer": "MAGIS.RINCHI_KAIHATSU_KYOKA",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "fill-color": "#00CC33",
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  // 林地開発 - 許可(ハイライト) ポリゴン
  {
    id: "shizuoka-林地開発-許可-hilight",
    type: "fill",
    source: "shizuokaRinchiKaihatsuKyokaSource",
    "source-layer": "MAGIS.RINCHI_KAIHATSU_KYOKA",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "all",
      false,
    ],
    paint: {
      "fill-color": "rgba(255, 255, 0, 0.4)",
    },
    layout: {
      visibility: "none"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
);

const tilesPath =
    process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
    "/MAGIS.RINCHI_KAIHATSU_KYOKA/{z}/{x}/{y}.pbf" +
    "?" + packageJson.lastupdate

const shizuokaRinchiKaihatsuKyoka = {
  layers: layers,
  source: {
    type: "vector",
    tiles: [tilesPath],
    minzoom: 4,
    maxzoom: 18
  }
};

export default shizuokaRinchiKaihatsuKyoka;
