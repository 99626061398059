const packageJson = require('/package.json');

// 市町村境界レイヤー一覧
const layers = [];

layers.push(
  // ポリゴンの外周(太)
  {
    id: "shizuoka-city-edge-halo",
    type: "line",
    source: "shizuokaCityEdgeSource",
    "source-layer": "MAGIS.CITY_EDGE",
    minzoom: 4,
    maxzoom: 20,
    paint: {
      "line-color": "white",
      "line-width": [
        "step",
        ["zoom"],
        5.0,        // Default
        9.5, 5.0,   // Zoom level threshold & value after the threshold
        10.5, 6.0,
        12.0, 7.0,
        13.0, 9.0,
        20.0, 9.0
      ]
    },
    metadata: {
      baseOpacity: 0.5
    }
  },
  // ポリゴンの外周(細)
  {
    id: "shizuoka-city-edge",
    type: "line",
    source: "shizuokaCityEdgeSource",
    "source-layer": "MAGIS.CITY_EDGE",
    minzoom: 4,
    maxzoom: 20,
    paint: {
      "line-color": "#00FF40",
      "line-width": [
        "step",
        ["zoom"],
        3.0,        // Default
        9.5, 3.0,   // Zoom level threshold & value after the threshold
        10.5, 4.0,
        12.0, 5.0,
        13.0, 7.0,
        20.0, 7.0
      ]
    },
    metadata: {
      baseOpacity: 0.6
    }
  },
  // ラベル
  {
    id: "shizuoka-city-edge-label",
    type: "symbol",
    source: "shizuokaCityEdgeSource",
    "source-layer": "MAGIS.CITY_EDGE",
    minzoom: 7.5,
    maxzoom: 20,
    paint: {
      "text-color": "black",
      "text-halo-color": "#C0FFC0",
      "text-halo-width": [
        "step",
        ["zoom"],
        1.5,
        12, 2.0,
        14, 2.5,
        15, 3.0,
        20, 3.0
      ]
    },
    layout: {
      "text-field": [
        "case",
        ["==", ["get", "市町村名"], ["get", "市区町村名"]],
        ["get", "市町村名"],
        ["concat", ["get", "市町村名"], "\n", ["get", "市区町村名"]]
      ],
      'text-size': 18,
      'text-anchor': 'center',
      'text-justify': 'center',
      "text-allow-overlap": true,
      "text-allow-overlap": [
        "step",
        ["zoom"],
        false,
        // 11から林班のラベルが表示されるので、そこからはオーバーラップさせる
        10.5, true,
        20.0, true
      ],
      "text-size": [
        "step",
        ["zoom"],
        14,       // Default
        13, 18,   // Zoom level threshold & value after the threshold
        14, 26,
        15, 32,
        20, 32
      ]
    },
    metadata: {
      baseOpacity: 1.0
    }
  },
);

const tilesPath =
    process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
    "/MAGIS.CITY_EDGE/{z}/{x}/{y}.pbf" +
    "?" + packageJson.lastupdate

const shizuokaCityEdge = {
  layers: layers,
  source: {
    type: "vector",
    tiles: [tilesPath],
    minzoom: 4,
    maxzoom: 20
  }
};

export default shizuokaCityEdge;
