const packageJson = require("/package.json");

const GROUP_MIN_ZOOM = 7.5;
const GROUP_MAX_ZOOM = 20;
const GROUP_BASE_OPACITY = 0.4;

// 森林の種類 レイヤー一覧
const layers = [];

layers.push(
  // 森林の種類 国有林 ポリゴン
  {
    id: "shizuoka-森林の種類-国有林",
    type: "fill",
    source: "shizuokaKokuyurinSource",
    "source-layer": "MAGIS.KOKUYURIN",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "fill-color": "#008080"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    // ※ デバッグ用
    id: "shizuoka-森林の種類-５条森林",
    type: "fill",
    source: "shizuokaShohanShinrinbo5JouSource",
    "source-layer": "MAGIS.SHOHAN_SHINRINBO.5JOU",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "fill-color": "#00CC00"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  // 森林の種類 2-5条森林 ポリゴン
  {
    id: "shizuoka-森林の種類-２－５条森林",
    type: "fill",
    source: "shizuokaShohanShinrinbo2minus5JouSource",
    "source-layer": "MAGIS.SHOHAN_SHINRINBO.2-5JOU",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "fill-color": "#CCCC00"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    // ※ デバッグ用
    id: "shizuoka-森林の種類-森林以外-000",
    type: "fill",
    source: "shizuokaShohanShinrinboNotShinrin_0Source",
    "source-layer": "MAGIS.SHOHAN_SHINRINBO.NOT-SHINRIN-0",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "fill-color": "#440044"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    // ※ デバッグ用
    id: "shizuoka-森林の種類-森林以外-9xx",
    type: "fill",
    source: "shizuokaShohanShinrinboNotShinrin_9xxSource",
    "source-layer": "MAGIS.SHOHAN_SHINRINBO.NOT-SHINRIN-9xx",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "fill-color": "#AA00AA"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
);

const shizuokaShinrinNoShurui = {
  layers: layers
};

export default shizuokaShinrinNoShurui;
