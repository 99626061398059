import axios from "axios";
import store from "@/store/index";
import distance from "@turf/distance";
import { point } from "@turf/helpers";
import * as PrefectureHelper from "./helpers/prefecture";

export default {
  namespace: true,
  state: {
    addresses: [],
    spots: [],
    keyword: null,
    pageIndex: null,
    canNext: false,
    canPrevious: false
  },
  getters: {
    addressSpotList: state => {
      let result = [];
      if (state.addresses) {
        state.addresses.map(val => {
          result.push({
            prefecture: val.prefecture,
            name: val.name,
            address: val.address,
            lat: val.lat,
            lng: val.lon
          });
        });
      }
      if (state.spots) {
        state.spots.map(val => {
          result.push({
            prefecture: val.prefecture,
            name: val.name,
            address: val.address,
            lat: val.lat,
            lng: val.lon,
            source: val.source,
            layerId: val.layerId,
            zoom: val.zoom,
            id: val.id
          });
        });
      }
      return result;
    }
  },
  mutations: {
    SET_ADDRESSES(state, addresses) {
      state.addresses = addresses;
    },
    SET_SPOTS(state, spots) {
      state.spots = spots;
    }
  },
  actions: {
    async getSpots({ commit }, keyword = null) {
      commit("SET_SPOTS", []);

      if (!keyword) {
        return;
      }
      // 両端のスペースをカット
      keyword = keyword.trim();

      const endpoint = "https://msearch.gsi.go.jp/address-search/AddressSearch";
      const query = `q=${encodeURIComponent(keyword)}`;

      return axios
        .get(`${endpoint}?${query}`, {
          transformRequest: (data, headers) => {
            if (headers && headers.common) {
              // Authorizationヘッダがあるとpreflightリクエストが飛んでCORSエラーになるため
              // 外部APIを呼び出すときは削除しておく
              delete headers.common["Authorization"];
            }
            return data;
          }
        })
        .then(response => {
          let items = response.data;

          // キーワードに半角スペースが入ったら複数検索する
          var keywordArr = [];
          if (keyword.indexOf(" ") >= 0) {
            keywordArr = keyword.split(" ");
          }

          // 「堺筋本町」
          // で検索すると
          // 「福島県桑折町界」「福島県南会津町界」「長野県栄村堺」「大阪府堺市」「大阪府堺市堺区」
          // 「和歌山県みなべ町堺」「長崎県長崎市界」「大分県豊後高田市界」「堺筋本町駅」
          // が返ってきてしまうので、ここで指定キーワードを含まないものは取り除く
          if (keywordArr.length === 0) {
            items = items.filter(item => item.properties.title.match(keyword));
          } else {
            for (const key of keywordArr) {
              items = items.filter(item => item.properties.title.match(key));
            }
          }

          // やたらめったら検索結果に表れるものを取り除く
          items = items.filter(
            item =>
              !(
                item.properties.title.match("消防署") ||
                item.properties.title.match("郵便局") ||
                item.properties.title.match("警察署")
              )
          );

          // 現在地から距離が短い順にソートする
          items.sort((a, b) => {
            const center = point([store.state.map.lng, store.state.map.lat]);
            const da = distance(center, point([a.geometry.coordinates[0], a.geometry.coordinates[1]]));
            const db = distance(center, point([b.geometry.coordinates[0], b.geometry.coordinates[1]]));
            if (da > db) {
              return 1;
            }
            if (da < db) {
              return -1;
            }
            return 0;
          });

          // 上位 100件だけを残す
          items = items.slice(0, 100);

          let spots = [];

          // 住所検索の結果
          for (const item of items) {

            // 都道府県を補う
            let prefecture = PrefectureHelper.getPrefecture(item.properties.addressCode, item.properties.title);
            if (prefecture != "") {
              // まれにtitleに都道府県が含まれている場合がある
              // (その場合、PrefectureHelper.getPrefectureが都道府県名は取りだしている)
              if (item.properties.title && item.properties.title.startsWith(prefecture)) {
                // titleの都道府県は取り除いておく
                item.properties.title = item.properties.title.substring(prefecture.length);
              }

              // セパレータはここで都道府県名に付けてしまう
              // （マージンで離すと行政区の方が右に寄ってしまうので）
              prefecture = prefecture + "　";
            } else {
              // 住所コードがあれば都道府県名の代わりに補っておく
              if (item.properties.addressCode != "") {
                prefecture = `(${item.properties.addressCode})　${prefecture}`;
              }
            }

            // TODO 市区町村を補う

            spots.push({
              prefecture: prefecture,
              name: item.properties.title,
              address: "（住所検索）",
              lat: item.geometry.coordinates[1],
              lon: item.geometry.coordinates[0],
              source: "",
              layerId: "",
              zoom: 12,
              id: ""
            });
          }
          commit("SET_SPOTS", spots);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
