const packageJson = require('/package.json');

const GROUP_SOURCE = "shizuokaShohanShinrinbo5JouSource";
const GROUP_SOURCE_LAYER = "MAGIS.SHOHAN_SHINRINBO.5JOU";
const GROUP_MIN_ZOOM = 12.5;
const GROUP_MAX_ZOOM = 20;
const GROUP_BASE_OPACITY = 0.5;

// 小班森林簿 / 林種レイヤー群
const layers = [];

layers.push(
  {
    id: "shizuoka-shinrinbo-林種-人工林",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "in",
      "林種CD",
      "1",    // 人工林（育成単層林）
      "2"     // 人工林（育成複層林）
    ],
    paint: {
      "fill-color": "#00A800"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-林種-天然林",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "in",
      "林種CD",
      "3",    // 天然林（育成単層林）
      "4",    // 天然林（育成複層林）
      "5"     // 天然林（天然生林）
    ],
    paint: {
      "fill-color": "#585800"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-林種-竹林",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "in",
      "林種CD",
      "6"     // 竹林
    ],
    paint: {
      "fill-color": "#0000A8"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-林種-伐採跡地等",
    type: "fill",
    source: GROUP_SOURCE,
    "source-layer": GROUP_SOURCE_LAYER,
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "in",
      "林種CD",
      "7",    // 伐採跡地
      "8",    // 未立木地
      "9"     // 更新困難地
    ],
    paint: {
      "fill-color": "#620046"
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
);

const shizuokaShinrinboLayers_Rinshu = {
  layers: layers
};

export default shizuokaShinrinboLayers_Rinshu;
