<template>
  <!-- システムタイトル -->
  <b-container class="container-div">
    <b-card class="toppage-wrap">
      <h2>　静岡県森林クラウド公開システム　</h2>
      <div class="version" v-show="showVersion">
        {{ this.$store.getters["const/clientVersion"] }}
        &nbsp;
        {{ this.$store.getters["const/envMode"] }}
      </div>
    </b-card>

    <b-container class="d-flex flex-row">
      <!-- 左ペイン -->
      <b-container class="d-flex flex-column">
        <!-- 注意事項 -->
        <div class="license-frame">
          <div
            class="frame-title"
            style="padding-top: 1rem;"
          >
            静岡県森林クラウド公開システム
          </div>
          <div
            class="frame-title"
            style="padding-top: 1rem; padding-bottom: 2rem;"
          >
            御利用にあたっての注意事項
          </div>
          <p>
            森林簿及び森林計画図は、
            <span class="red-underline">
              所有者権等土地に関する諸権利、所有界、面積及び木竹の評価等を証明するものではありません。
            </span>
          </p>
          <p>
            また、森林簿の情報は現況と異なる場合があります。
          </p>
          <p>
            詳しくは、
            <a :href="documentRootInfo + '/Ryuuiten.html'" target="info">
              利用上の留意事項
            </a>
            を御一読いただき、留意事項のすべてに同意される方は、次にお進みください。
          </p>
          <b-form
            v-on:submit.prevent="agreeLicense()"
          >
            <div>
              <b-button
                type="submit"
                class="action-button"
                id="toppage-production"
                variant="primary"
                style="background: #ff7900"
              >
                同意する
              </b-button>
            </div>
          </b-form>
        </div>
      </b-container>

      <!-- 右ペイン -->
      <b-container class="d-flex justify-content-end">
        <b-container class="d-flex flex-column">
          <!-- お知らせ -->
          <iframe
            class="infomation-iframe"
            :src="iInfoSource"
            frameborder="0"
          ></iframe>

          <!-- 関連リンク -->
          <div class="link-list">
            <div
              class="frame-title"
              style="padding: 1rem 0 1rem 0"
            >
              関連リンク
            </div>

            <div class="d-grid grid-template-columns">
              <div>
                <!-- 静岡県の森林と林業 -->
                <a
                  href="https://www.pref.shizuoka.jp/sangyoshigoto/ringyo/1003263/index.html"
                  target="_blank"
                >
                  <img class="link-img" :src="ringyouImgSrc" />
                </a>
              </div>

              <div>
                <!-- しずおか 木使い -->
                <a
                  href="https://www.pref.shizuoka.jp/kurashikankyo/kenchiku/kizukai/1054945/"
                  target="_blank"
                >
                  <img class="link-img" :src="kidukaiImgSrc" />
                </a>
              </div>

              <div>
                <!-- 静岡 森づくり情報 -->
                <a
                  href="https://www.pref.shizuoka.jp/kurashikankyo/shizenkankyo/morizukuri/"
                  target="_blank"
                >
                  <img class="link-img" :src="moridukuriImgSrc" />
                </a>
              </div>

              <div>
                <!-- 森林の土地所有者届出制度 対象森林の確認方法 -->
                <a
                  href="https://www.pref.shizuoka.jp/sangyoshigoto/ringyo/shinrinkeikaku/1026804.html"
                  target="_blank"
                >
                  <img class="link-img" :src="shinrinTodokedeImgSrc" />
                </a>
              </div>

              <div>
                <!-- Open Data Catalog Shizuoka -->
                <a href="https://opendata.pref.shizuoka.jp/" target="_blank">
                  <img class="link-img" :src="DataCatalogImgSrc" />
                </a>
              </div>
            </div><!-- class="d-grid grid-template-columns" -->
          </div><!-- class="link-list" -->
        </b-container>
      </b-container>
    </b-container>

    <!-- フッター -->
    <b-navbar class="fixed-bottom w-100 justify-content-center">
      <b-nav>
        <b-nav-item
          :href="documentRootInfo + '/UneiHousin.html'"
          class="footer-link"
          target="info"
        >
          システム運営方針
        </b-nav-item>
        <b-nav-item
          :href="documentRootInfo + '/Data.html'"
          class="footer-link"
          target="info"
        >
          データの作成と時期
        </b-nav-item>
        <b-nav-item
          :href="documentRootInfo + '/Q&A.html'"
          class="footer-link"
          target="info"
        >
          よくある質問
        </b-nav-item>
<!--
        <b-nav-item
          :href="documentRootInfo + '/manual.pdf'"
          class="footer-link"
          target="manual"
        >
          システムの操作方法
        </b-nav-item>
-->
      </b-nav>
    </b-navbar>
  </b-container>
</template>

<script>
import router from "../../router";

export default {
  name: "TopPage",
  data() {
    return {
      isLicenseAgreed: false,
      iframeSource:
        this.$store.getters["const/publicPath"] + "/license-agreement.html",
      iInfoSource:
        this.$store.getters["const/managementPath"] + "/information.html",
      moridukuriImgSrc:
        this.$store.getters["const/featureIconPath"] +
        "shizuoka-moridukuri.jpg",
      kankyougakushuuImgSrc:
        this.$store.getters["const/featureIconPath"] +
        "kankyougakushuu-databank.gif",
      DataCatalogImgSrc:
        this.$store.getters["const/featureIconPath"] +
        "open-data-catalog-shizuoka.gif",
      shinrinTodokedeImgSrc:
        this.$store.getters["const/featureIconPath"] +
        "shinrin-tochishoyuushatodokedeseido.gif",
      kidukaiImgSrc:
        this.$store.getters["const/featureIconPath"] + "shizuoka-kidukai.jpg",
      ringyouImgSrc:
        this.$store.getters["const/featureIconPath"] +
        "shizuokaken-shinrin-ringyou.gif"
    };
  },
  computed: {
    documentRootInfo: function () {
      return process.env.VUE_APP_DOCUMENT_ROOT_INFO;
    },
    showVersion: function () {
      const mode = this.$store.getters["const/envMode"];
      return (mode !== "");  // ブランクは本番環境
    }
  },
  mounted() {
    console.log(`${this.$store.getters["const/clientVersion"]} ${this.$store.getters["const/envMode"]}`);
  },
  methods: {
    agreeLicense: function () {
      // 利用規約に同意済み
      this.$store.commit("agreement/storeIsLicenseAgreed", {
        isAgreed: true
      });

      const redirectUrl = this.$route.query.redirect || '/';
console.log(`redirectUrl=${redirectUrl}`);
      const version = this.$route.query.version;
console.log(`version=${version}`);
      this.$router.push({ path: redirectUrl, query: { version: version }});

      // 地図画面へ
      /*
      router.push("/map").catch(failure => {
        console.log(failure);
      });
      */
    }
  }
};
</script>

<style scoped>
.container-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2em;
}

.toppage-wrap {
  width: fit-content;
  margin: auto;
}

.version {
  text-align: center;
  font-size: 12px;
  color: gray;
}

.frame-title {
  font-size: 21px;
  font-weight: bold;
  text-align: center;
}

.license-frame {
  margin-top: 30px;
  height: calc(100vh - 272px);
  border: 1px solid #00000020;
  border-radius: 0.3rem;
  padding: 0.5rem 3rem;
}

.license-frame p {
  font-size: 12pt;
  text-align: left;
}

.infomation-iframe {
  margin-top: 30px;
  height: calc(100vh - 580px);
  border: 1px solid #00000020;
  border-radius: 0.3rem;
  padding: 0.5rem;
}

.action-button {
  border: 0px;
  width: 80%;
  height: 4rem;
  margin-top: 4rem;
}

.grid-template-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
}

.link-list {
  border: 1px solid #00000020;
  margin-top: 28px;
  height: 280px;
  padding: 0.5rem;
  border-radius: 0.3rem;
}

.link-img {
  width: 160px;
  height: 40px;
  margin-bottom: 24px;
}

a {
  color: blue;
  text-decoration: underline;
}

a:visited {
  color: blue;
}

a:hover {
  text-decoration: underline;
}

.footer-link {
  margin: 0 0 1rem 0;
  color: blue;
  text-decoration: underline;
}

.red-underline {
  color: red;
  text-decoration: underline;
}
</style>
