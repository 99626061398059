export default {
  namespaced: true,
  state: {
    config: getConfig()
  },
  getters: {
    // クライアントバージョン
    clientVersion: () => {
      return require('/package.json').version;
    },
    // コンテキストパス
    publicPath: () => {
      return process.env.VUE_APP_PUBLIC_PATH;
    },
    envMode: () => {
      return process.env.VUE_APP_ENV_LABEL;
    },
    // 地物アイコンパス
    featureIconPath: (state, getters) => {
      return getters.publicPath + "/feature-icon/";
    },
    // APIエンドポイント
    apiEndpoint: () => {
      return process.env.VUE_APP_API_ENDPOINT;
    },
    // 初期値 経度
    homeLng: () => {
      return process.env.VUE_APP_HOMELNG;
    },
    // 初期値 緯度
    homeLat: () => {
      return process.env.VUE_APP_HOMELAT;
    },
    // 初期値 ズーム
    homeZoom: () => {
      return process.env.VUE_APP_HOMEZOOM;
    },
    // 現在地 ズーム
    myLocationZoom: () => {
      return 15;
    },
    // 最終更新日時
    lastUpdate: () => {
      return require('/package.json').lastupdate;
    },
    managementPath: () => {
      return process.env.VUE_APP_MANAGEMENT_PATH;
    }
  }
};


export const SearchModes = {
  NONE: "NONE",
  KEYWORD: "KEYWORD",
  COORDINATE: "COORDINATE",
  ADVANCED_SHINRINBO: "ADVANCED_SHINRINBO",
  ADVANCED_RINDO: "ADVANCED_RINDO",
  ADVANCED_MORI_NO_CHIKARA: "ADVANCED_MORI_NO_CHIKARA",
  ADVANCED_KEIEI_KEIKAKU: "ADVANCED_KEIEI_KEIKAKU",
  ADVANCED_RINCHI_KAIHATSU: "ADVANCED_RINCHI_KAIHATSU",
  ADVANCED_SEIBI_JIGYOU: "ADVANCED_SEIBI_JIGYOU",
}


// publicフォルダから設定ファイルを取得
function getConfig() {
  const xhr = new XMLHttpRequest();
  let result = {};

  xhr.onreadystatechange = () => {
    // レスポンスが完了(readStatus:4)且つ、通信成功(status:200)
    if (xhr.readyState == 4 && xhr.status == 200) {
      result = JSON.parse(xhr.responseText);
    }
  };

  xhr.open("GET", process.env.VUE_APP_PUBLIC_PATH + "/config.json", false);
  xhr.send(null);

  return result;
}
