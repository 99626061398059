const packageJson = require('/package.json');

export const shizuokaShohanShinrinbo2minus5Jou = {
  source: {
    type: "vector",
    tiles: [
      process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
      "/MAGIS.SHOHAN_SHINRINBO.2-5JOU/{z}/{x}/{y}.pbf" +
      "?" + packageJson.lastupdate
    ],
    minzoom: 4,
    maxzoom: 20
  }
};

export const shizuokaShohanShinrinboNotShinrin_0 = {
  source: {
    type: "vector",
    tiles: [
      process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
      "/MAGIS.SHOHAN_SHINRINBO.NOT-SHINRIN-0/{z}/{x}/{y}.pbf" +
      "?" + packageJson.lastupdate
    ],
    minzoom: 4,
    maxzoom: 20
  }
};

export const shizuokaShohanShinrinboNotShinrin_9xx = {
  source: {
    type: "vector",
    tiles: [
      process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
      "/MAGIS.SHOHAN_SHINRINBO.NOT-SHINRIN-9xx/{z}/{x}/{y}.pbf" +
      "?" + packageJson.lastupdate
    ],
    minzoom: 4,
    maxzoom: 20
  }
};
