const packageJson = require('/package.json');

// 林班レイヤー一覧
const layers = [];

// 林班
layers.push(
  // ポリゴンの外周(太)
  {
    id: "shizuoka-rinpan-halo",
    type: "line",
    source: "shizuokaRinpanSource",
    "source-layer": "MAGIS.RINPAN",
    minzoom: 7.5,
    maxzoom: 20,
    filter: [
      "all",
      ["!=", ["get", "林班"], 0]
    ],
    paint: {
      "line-color": "white",
      "line-width": [
        "step",
        ["zoom"],
        1.5,        // Default
        9.5, 3.5,   // Zoom level threshold & value after the threshold
        10.5, 4.0,
        12.0, 5.0,
        20.0, 5.0
      ]
    },
    metadata: {
      baseOpacity: 0.5
    }
  },
  // ポリゴンの外周(細)
  {
    id: "shizuoka-rinpan",
    type: "line",
    source: "shizuokaRinpanSource",
    "source-layer": "MAGIS.RINPAN",
    minzoom: 7.5,
    maxzoom: 20,
    filter: [
      "all",
      ["!=", ["get", "林班"], 0]
    ],
    paint: {
      "line-color": "red",
      "line-width": [
        "step",
        ["zoom"],
        0.5,        // Default
        9.5, 0.5,   // Zoom level threshold & value after the threshold
        10.5, 1.0,
        12.0, 2.0,
        20.0, 2.0
      ]
    },
    metadata: {
      baseOpacity: 0.5
    }
  },
  // ラベル
  {
    id: "shizuoka-rinpan-label",
    type: "symbol",
    source: "shizuokaRinpanSource",
    "source-layer": "MAGIS.RINPAN",
    minzoom: 11,
    maxzoom: 20,
    filter: [
      "all",
      ["!=", ["get", "林班"], 0]
    ],
    paint: {
      "text-color": "red",
      "text-halo-color": "white",
      "text-halo-width": [
        "step",
        ["zoom"],
        0.5,
        12, 1.0,
        14, 1.5,
        15, 2.0,
        20, 2.0
      ]
    },
    layout: {
      "text-field": ['get', '林班'],
      'text-anchor': 'center',
      'text-justify': 'center',
      "text-allow-overlap": true,
      "text-ignore-placement": false,
      "text-variable-anchor": ["bottom", "top"],
      "text-size": [
        "step",
        ["zoom"],
        10,       // Default
        12, 14,   // Zoom level threshold & value after the threshold
        13, 18,
        14, 26,
        15, 32,
        20, 32
      ]
    },
    metadata: {
      baseOpacity: 1.0
    }
  },
);

const tilesPath =
    process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
    "/MAGIS.RINPAN/{z}/{x}/{y}.pbf" +
    "?" + packageJson.lastupdate

const shizuokaRinpan = {
  layers: layers,
  source: {
    type: "vector",
    tiles: [tilesPath],
    minzoom: 4,
    maxzoom: 18
  }
};

export default shizuokaRinpan;
