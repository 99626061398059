const packageJson = require('/package.json');

const GROUP_MIN_ZOOM = 7.5;
const GROUP_MAX_ZOOM = 20;
const GROUP_BASE_OPACITY = 0.5;

// 森の力 レイヤー一覧
const layers = [];

layers.push(
  // 森の力(実施箇所) ポリゴン
  {
    id: "shizuoka-森の力-実施箇所",
    type: "fill",
    source: "shizuokaMoriNoChikaraSource",
    "source-layer": "MAGIS.MORI_NO_CHIKARA",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "fill-color": "#008000",
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  // 森の力(実施箇所) ハイライト
  {
    id: "shizuoka-森の力-実施箇所-hilight",
    type: "fill",
    source: "shizuokaMoriNoChikaraSource",
    "source-layer": "MAGIS.MORI_NO_CHIKARA",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "all",
      false
    ],
    paint: {
      "fill-color": "rgba(255, 255, 0, 0.4)",
    },
    layout: {
      visibility: "none"
    },
    metadata: {
      baseOpacity: 0.8
    }
  }
);

const tilesPath =
    process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
    "/MAGIS.MORI_NO_CHIKARA/{z}/{x}/{y}.pbf" +
    "?" + packageJson.lastupdate

const shizuokaMoriNoChikara = {
  layers: layers,
  source: {
    type: "vector",
    tiles: [tilesPath],
    minzoom: 4,
    maxzoom: 18
  }
};

export default shizuokaMoriNoChikara;
