const packageJson = require('/package.json');

const GROUP_ENDPOINT = process.env.VUE_APP_SHIZUOKA_RASTER_ENDPOINT + "/MAGIS.SHOHAN_SHINRINBO.5JOU.ゾーニング.";
const GROUP_SOURCE = "shizuokaShohanShinrinbo5Jou_Zoning_";
const GROUP_MIN_ZOOM = 7;
const GROUP_MAX_ZOOM = 12.5;
const GROUP_BASE_OPACITY = 0.5;

// ゾーニング（ラスター）レイヤー群
const layers = [];

layers.push(
  {
    id: "shizuoka-shinrinbo-ゾーニング-raster-水源涵養機能維持増進",
    type: "raster",
    source: GROUP_SOURCE + "水源涵養機能維持増進",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "水源涵養機能維持増進" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-ゾーニング-raster-山地災害防止",
    type: "raster",
    source: GROUP_SOURCE + "山地災害防止",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "山地災害防止" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-ゾーニング-raster-快適環境形式機能",
    type: "raster",
    source: GROUP_SOURCE + "快適環境形式機能",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "快適環境形式機能" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-ゾーニング-raster-保健文化機能維持増進",
    type: "raster",
    source: GROUP_SOURCE + "保健文化機能維持増進",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "保健文化機能維持増進" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-ゾーニング-raster-木材生産機能維持増進",
    type: "raster",
    source: GROUP_SOURCE + "木材生産機能維持増進",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "木材生産機能維持増進" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
);

const shizuokaShinrinboLayers_ZoningRaster = {
  layers: layers
};

export default shizuokaShinrinboLayers_ZoningRaster;
