const packageJson = require("/package.json");

// Skyレイヤー
const layers = [];

layers.push(
  {
    'id': 'sky',
    'type': 'sky',
    'paint': {
        'sky-type': 'gradient',
        'sky-gradient': [
            'interpolate',
            ['linear'],
            ['sky-radial-progress'],
            0.8,
            'rgba(135, 206, 235, 1)', // 空の色1 (ここでは淡い青色)
            1,
            'rgba(255, 255, 255, 1)'  // 空の色2 (ここでは白色)
        ]
    }
  }
);

const sky = {
  layers: layers
};

export default sky;
