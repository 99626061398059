import Vue from "vue";
import Vuex from "vuex";

import ConstModule, { SearchModes } from "./constModule";
import MapModule from "./mapModule";
import BasemapSetModule from "./basemapSetModule";
import FeatureSetModule from "./featureSetModule";
import KeywordSearchModule from "./keywordSearchModule";
import CoordinateSearchModule from "./coordinateSearchModule";
import AdvancedSearchModule from "./advancedSearchModule";
import ManagementAuthModule from "./managementAuthModule";
import AgreementModule from "./agreementModule";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    searchMode: SearchModes.NONE,
    isLoading: false
  },
  mutations: {
    SET_SEARCH_MODE(state, mode){
      if (Object.values(SearchModes).includes(mode)) {
        state.searchMode = mode;
      } else {
        state.searchMode = SearchModes.NONE;
      }
    },
    SET_LOADING(state, val) {
      state.isLoading = val;
    }
  },
  actions: {
    updateSearchMode({ commit }, mode) {
      commit('SET_SEARCH_MODE', mode);
    },
    updateLoading({ commit }, val) {
      commit('SET_LOADING', val);
    }
  },
  strict: true,
  modules: {
    const: ConstModule,
    map: MapModule,
    basemapSet: BasemapSetModule,
    featureSet: FeatureSetModule,
    keywordSearch: KeywordSearchModule,
    coordinateSearchModule: CoordinateSearchModule,
    advancedSearch: AdvancedSearchModule,
    managementAuth: ManagementAuthModule,
    agreement: AgreementModule
  }
});
