const packageJson = require("/package.json");

const GROUP_ENDPOINT = process.env.VUE_APP_SHIZUOKA_RASTER_ENDPOINT + "/MAGIS.SHOHAN_SHINRINBO.5JOU.森の力.";
const GROUP_SOURCE = "shizuokaShohanShinrinbo5Jou_MoriNoChikara_";
const GROUP_MIN_ZOOM = 7.5;
const GROUP_MAX_ZOOM = 12.5;
const GROUP_BASE_OPACITY = 0.5;

// 小班森林簿 / 森の力 再生事業対象地レイヤー群
const layers = [];

layers.push(
  {
    id: "shizuoka-shinrinbo-森の力-raster-再生事業対象地",
    type: "raster",
    source: GROUP_SOURCE + "再生事業対象地",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "再生事業対象地" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  {
    id: "shizuoka-shinrinbo-森の力-raster-再生事業対象地（6,7以外）",
    type: "raster",
    source: GROUP_SOURCE + "再生事業対象地_67以外",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    metadata: {
      source: {
        type: "raster",
        tiles: [
          GROUP_ENDPOINT + "再生事業対象地（6,7以外）" + "/{z}/{x}/{y}.png" +
          "?" + packageJson.lastupdate
        ],
        minzoom: 4,
        maxzoom: 13,
        tileSize: 256
      },
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
);

const shizuokaShinrinboLayers_MoriNoChikaraRaster = {
  layers: layers
};

export default shizuokaShinrinboLayers_MoriNoChikaraRaster;
