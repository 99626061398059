const packageJson = require('/package.json');

const GROUP_MIN_ZOOM = 7.5;
const GROUP_MAX_ZOOM = 20;
const GROUP_BASE_OPACITY = 0.5;

// 森林整備事業 レイヤー一覧
const layers = [];

layers.push(
  // 森林整備事業 - 森林整備事業 ポリゴン
  {
    id: "shizuoka-森林整備事業",
    type: "fill",
    source: "shizuokaShinrinSeibiJigyouSource",
    "source-layer": "SHINRIN_SEIBI_JIGYOU",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    paint: {
      "fill-color": "#CC00FF",
    },
    metadata: {
      baseOpacity: GROUP_BASE_OPACITY
    }
  },
  // 森林整備事業 - 森林整備事業(ハイライト) ポリゴン
  {
    id: "shizuoka-森林整備事業-hilight",
    type: "fill",
    source: "shizuokaShinrinSeibiJigyouSource",
    "source-layer": "SHINRIN_SEIBI_JIGYOU",
    minzoom: GROUP_MIN_ZOOM,
    maxzoom: GROUP_MAX_ZOOM,
    filter: [
      "all",
      false
    ],
    paint: {
      "fill-color": "rgba(255, 255, 0, 0.4)"
    },
    layout: {
      visibility: "none"
    },
    metadata: {
      baseOpacity: 0.8
    }
  }
);

const tilesPath =
    process.env.VUE_APP_SHIZUOKA_MVT_ENDPOINT +
    "/SHINRIN_SEIBI_JIGYOU/{z}/{x}/{y}.pbf" +
    "?" + packageJson.lastupdate

const shizuokaShinrinSeibiJigyou = {
  layers: layers,
  source: {
    type: "vector",
    tiles: [tilesPath],
    minzoom: 4,
    maxzoom: 18
  }
};

export default shizuokaShinrinSeibiJigyou;
